<template>
    <radial-progress-bar :diameter="58"
                         :strokeWidth="5"
                         :innerStrokeWidth="5"
                         :startColor="barColor"
                         :stopColor="barColor"
                         :innerStrokeColor="'#e6e8ea'"
                         :completed-steps="completedSteps=='?'?0:completedSteps"
                         :animateSpeed="2000"
                         :total-steps="100">
        <p :style="'color:'+barColor">{{ completedSteps }}</p>
    </radial-progress-bar>
</template>
<script>
    import RadialProgressBar from 'vue-radial-progress'
    export default {
        name: "page-speed-bar",
        components: {
            RadialProgressBar
        },
        computed:{
            barColor(){
                return this.detectColor(this.completedSteps)[1];
            },
            completedSteps(){
                let score = this.score;
                if(score==-1){ return '?';}
                if(score<0) score=0;
                else if(score>100) score = 100;
                return Math.round(score);
            }
        },
        data: function() {
            return  {
                color:['rgb(155, 155, 155)', 'rgb(251, 104, 104)', 'rgb(252, 148, 44)','rgb(42, 201, 161)']
            }
        },
        methods: {
            detectColor(score){
                let color = 0;
                if(score>=90 && score<=100 ){
                    color = 3;
                }
                else if(score >= 50 && score < 90){
                    color = 2;
                }
                else if(score > 0 && score < 50){
                    color = 1;
                }
                else if(score===null){
                    color = 0;
                }
                return [color, this.color[color]];
            }
        },
        mounted(){
        },
        props:{
            score: Number
        }
    }
</script>
<style scoped>
 p{
     font-weight: bold;
     font-size: 15px;
 }
</style>
/* eslint-disable */
import axios from 'axios'
//import SNotification from "../../components/SNotification"
//import RadialProgressBar from 'vue-radial-progress'
import pageSpeedBar from "../../components/pageSpeedBar"

export default {
    name: "pageSpeed",
    components: {
        pageSpeedBar
        //SNotification
    },
    computed:{
        lang(){
            return this.$root.lang;
        }
    },
    created() {
        window.addEventListener('scroll', this.onScrollToBottom);
        window.addEventListener('scroll', this.onScrollFixed);
    },
    destroyed () {
        window.removeEventListener('scroll', this.onScrollToBottom);
        window.removeEventListener('scroll', this.onScrollFixed);
    },
    data: function() {
        return  {
            ll:null,
            color:['rgb(155, 155, 155)', 'rgb(251, 104, 104)', 'rgb(252, 148, 44)','rgb(42, 201, 161)'], //  'rgb(12, 206, 107)'
            completedSteps: 45,
            sites: [],
            moreInfo: null,
            projectFilter:'',
            triggerPrFilter:null,
            page: 1,
            triggerStart:false,
            noLoadMore:false,
            detailsFixed: false,
            mobileVer: false,
            showDetail: false
        }
    },
    methods: {
        async init() {
            //this.route();
            await this.getll();
            await this.main();
            if(document.body.clientWidth<980) this.mobileVer = true;
        },
        async getll() {
            let res = await axios.post('/api/main/get',{datall: {"lang": this.lang,"controller": ["page-speed"] } } );
            if (res && res.data && res.data.datall) {
                this.ll = res.data.datall;
            }
        },
        async main(){
            if(this.triggerStart) return;
            this.triggerStart = true;
            let res = await axios.post('/api/pagespeed/sites',{ page: this.page, filter:this.projectFilter } );
            if (res && res.data){
                if(res.data.result){
                    if(res.data.result.length>0) this.sites = this.sites.concat(res.data.result);
                    else this.noLoadMore=true;
                }
                if(!res.data.user) this.$router.push('/page-speed');
                this.triggerStart = false;

            }
        },
        detectColor(score){
            let color = 0;
            if(score>=0.9 && score<=1 ){
                color = 3;
            }
            else if(score >= 0.5 && score < 0.9){
                color = 2;
            }
            else if(score > 0 && score < 0.5){
                color = 1;
            }
            else if(score===null){
                color = 0;
            }
            return [color, this.color[color]];
        },
        setColor(val){
            if(val>=0 && val<4) return this.color[val];
            return '';
        },
        showInfo(row){
			for(let jj in this.sites) this.sites[jj].clickInfo = false;

            if(row && row.desktop && row.mobile ){
				this.moreInfo = row;
				this.moreInfo.link = '/page-speed?mode=desktop&url='+row.page+'&id='+row.id;
				row.clickInfo = true;

                if(this.mobileVer) this.showDetail = true;
            }
            else this.moreInfo = null;
        },
        changeKwFilter(){
            clearTimeout(this.triggerPrFilter);
            this.triggerPrFilter = setTimeout(()=>{
                if(this.projectFilter.length>2 || this.projectFilter.length==0){
                    this.sites = [];
                    this.noLoadMore = false;
                    this.page=1;
                    this.main();
                }
            },800);
        },
        onScrollToBottom(){
            if(this.triggerStart || this.noLoadMore) return;
            let OFFSET = 400;
            let scrollBottom = this.scrollTop() + window.innerHeight;
            let docHeight = document.body.scrollHeight;
            if (scrollBottom > docHeight - OFFSET) {
                this.page ++;
                this.main();
            }
        },
        onScrollFixed(){
            if(this.mobileVer) return;
            let scrollTop = this.scrollTop();
            let height = document.body.scrollHeight;

            if(scrollTop>200){
                if(scrollTop>height-750) this.detailsFixed = false;
                else this.detailsFixed = true;
            }
            else{
                this.detailsFixed = false;
            }
            //console.log(scrollTop, height);
        },
        scrollTop() {
            return window.scrollY != null ? window.scrollY : window.pageYOffset;
        }
    },
    mounted(){
        this.init();
    },
    watch:{
        lang(to, from){
            this.getll();
        }
    }
}
